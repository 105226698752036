var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wordpress-dialog text-center" },
    [
      _c(
        "v-dialog",
        {
          attrs: { width: _vm.width },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function({ on, attrs }) {
                return [
                  _c(
                    "div",
                    _vm._g(
                      _vm._b(
                        { domProps: { innerHTML: _vm._s(_vm.activator) } },
                        "div",
                        attrs,
                        false
                      ),
                      on
                    )
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _vm.title
                ? _c(
                    "v-card-title",
                    { staticClass: "text-h2 grey lighten-2" },
                    [_vm._v(" " + _vm._s(_vm.title) + " ")]
                  )
                : _vm._e(),
              _c("div", { domProps: { innerHTML: _vm._s(_vm.content) } })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }