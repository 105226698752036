var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "cities-list-container" },
    [
      _c("v-text-field", {
        attrs: {
          label: "Trova il tuo comune",
          placeholder: "Città",
          dense: "",
          outlined: ""
        },
        on: { input: _vm.filterCities }
      }),
      _c(
        "div",
        { staticClass: "list", style: `height: ${_vm.height}px` },
        _vm._l(_vm.citiesFiltered, function(city) {
          return _c("v-list-item", { key: city.id, attrs: { dense: "" } }, [
            _vm._v(" " + _vm._s(city.name) + " ")
          ])
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }