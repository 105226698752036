var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "shop-at-work-subscription" },
    [
      _c(
        "v-form",
        {
          ref: "form",
          staticClass: "d-flex",
          model: {
            value: _vm.isValid,
            callback: function($$v) {
              _vm.isValid = $$v
            },
            expression: "isValid"
          }
        },
        [
          _c("v-text-field", {
            attrs: {
              color: "secondary",
              label: _vm.$t("shopAtWork.inputText"),
              outlined: "",
              dense: "",
              rules: [_vm.requiredRules()],
              required: ""
            },
            model: {
              value: _vm.companyCode,
              callback: function($$v) {
                _vm.companyCode = $$v
              },
              expression: "companyCode"
            }
          }),
          _c(
            "v-btn",
            {
              staticClass: "secondary subscribe-button",
              attrs: { height: "40", large: "", depressed: "" },
              on: {
                click: function($event) {
                  return _vm.subscribeCompany()
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("shopAtWork.subscribeBtn")))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }