var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "geococde-map-container" },
    [
      _c("GmapMap", {
        ref: "mapRef",
        style: `width: 100%; min-height: ${_vm.height}px`,
        attrs: {
          center: JSON.parse(_vm.center),
          zoom: parseInt(_vm.zoom),
          options: {
            zoomControl: false,
            mapTypeControl: false,
            streetViewControl: false
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }