var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page" },
    [
      _vm.page
        ? _c(_vm.templateName, {
            key: _vm.key,
            tag: "component",
            attrs: { page: _vm.page, additionalData: _vm.additionalData }
          })
        : _c("not-found")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }