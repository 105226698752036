var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.loading
    ? _c("ProductListSlider", {
        attrs: {
          categoryId: _vm.categoryId,
          productList: _vm.productList,
          layout: _vm.layout,
          shuffle: _vm.shuffle,
          promo: _vm.promo,
          limit: _vm.limit,
          title: _vm.title,
          paginationClass: _vm.paginationClass
        }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }