var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.product && _vm.product.priceDisplay && _vm.product.available > 0
    ? _c(
        "div",
        { staticClass: "actions my-1" },
        [
          _vm.showPrice
            ? _c("ProductPrice", { attrs: { product: _vm.product } })
            : _vm._e(),
          _c("ProductQty", {
            attrs: {
              selectedOptions: _vm.selectedOptions,
              product: _vm.product,
              item: _vm.item,
              label: _vm.label,
              "gift-code": _vm.giftCode
            }
          })
        ],
        1
      )
    : _c(
        "div",
        {
          staticClass:
            "actions justify-center text-center text-caption error--text font-weight-bold"
        },
        [_vm._v(" " + _vm._s(_vm.$t("product.notAvailable")) + " ")]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }