var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wordpress-slider-container slider banner-slider" },
    [
      _c(
        "swiper",
        { ref: "swiperRef", attrs: { options: _vm.swiperOption } },
        _vm._l(_vm.slides, function(slide) {
          return _c(
            "swiper-slide",
            {
              key: slide.menu_order,
              ref: _vm.swiperRef,
              refInFor: true,
              class: _vm.design === "design-8" ? "ma-0" : null
            },
            [
              _vm.design === "design-7" || _vm.design === "card"
                ? _c(
                    "div",
                    [
                      _c(
                        "v-card",
                        {
                          class: "ma-2 text-center " + _vm.sliderId,
                          attrs: {
                            href: slide.link,
                            elevation: "0",
                            outlined: ""
                          },
                          on: { click: _vm.clicked }
                        },
                        [
                          _c("img", {
                            staticStyle: { display: "block", margin: "0 auto" },
                            attrs: { src: slide.featured_image_url }
                          }),
                          _c("v-card-text", {
                            staticClass: "swiper-slider-overlay text-center",
                            domProps: { innerHTML: _vm._s(slide.title) }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm.design === "design-8"
                ? _c("div", [
                    _c(
                      "div",
                      {
                        staticClass:
                          "wpsisac-image-slide slick-slide slick-cloned slick-active"
                      },
                      [
                        _c("div", { staticClass: "container-point" }, [
                          _c("div", { staticClass: "timeline-point" }),
                          _c("div", { staticClass: "timeline-line" })
                        ]),
                        _c("div", { staticClass: "wpsisac-image-slide-wrap" }, [
                          _c("div", { staticClass: "wpsisac-slider-overlay" }, [
                            _c("div", { staticClass: "container-point" }, [
                              _c("div", { staticClass: "timeline-point" }),
                              _c("div", { staticClass: "timeline-line" })
                            ]),
                            _c(
                              "div",
                              { staticClass: "wpsisac-slider-content" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "wpsisac-slider-short-content"
                                  },
                                  [
                                    _c("div", {
                                      domProps: {
                                        innerHTML: _vm._s(slide.title)
                                      }
                                    }),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "after-content",
                                        attrs: { id: "" }
                                      },
                                      [_c("div", { staticClass: "container" })]
                                    )
                                  ]
                                )
                              ]
                            )
                          ]),
                          _c("img", {
                            staticClass: "swiper-slide-img",
                            staticStyle: { "max-width": "100%" },
                            attrs: { src: slide.featured_image_url }
                          })
                        ])
                      ]
                    )
                  ])
                : _c("div", [
                    slide.link
                      ? _c("a", { attrs: { href: slide.link } }, [
                          _c("img", {
                            staticClass: "swiper-slide-img",
                            staticStyle: { "max-width": "100%" },
                            attrs: { src: slide.featured_image_url }
                          })
                        ])
                      : _c("img", {
                          staticClass: "swiper-slide-img",
                          staticStyle: { "max-width": "100%" },
                          attrs: { src: slide.featured_image_url }
                        })
                  ])
            ]
          )
        }),
        1
      ),
      _vm.showBullets && _vm.slides.length > 1
        ? _c("div", {
            class: `swiper-pagination banner-pagination-${_vm.paginationClass}`,
            attrs: { id: `banner-pagination-${_vm.paginationClass}` }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }