var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.posts
    ? _c(
        "div",
        { staticClass: "w-100 slider-main-wrapper" },
        [
          _c(
            "swiper",
            {
              ref: "swiperRef",
              staticClass: "w-100 h-100",
              attrs: { options: _vm.swiperOption }
            },
            _vm._l(_vm.posts, function(post) {
              return _c(
                "swiper-slide",
                { key: post.id },
                [
                  _c("PostCard", {
                    attrs: { post: post, isWeLoveTrees: _vm.isWeLoveTrees }
                  })
                ],
                1
              )
            }),
            1
          ),
          _vm.showBullets && _vm.posts.length > 1
            ? _c("div", {
                staticClass: "swiper-pagination",
                attrs: { id: `card-slider-pagination-${_vm.paginationClass}` }
              })
            : _vm._e(),
          _vm.showArrows &&
          _vm.posts.length > 1 &&
          _vm.$vuetify.breakpoint.mdAndUp
            ? _c("div", {
                staticClass: "swiper-button-prev",
                attrs: { id: `card-slider-slider-prev-${_vm.paginationClass}` }
              })
            : _vm._e(),
          _vm.showArrows &&
          _vm.posts.length > 1 &&
          _vm.$vuetify.breakpoint.mdAndUp
            ? _c("div", {
                staticClass: "swiper-button-next",
                attrs: { id: `card-slider-slider-next-${_vm.paginationClass}` }
              })
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }