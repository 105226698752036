var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wp-page", on: { click: _vm.clicked } },
    [
      _vm.title
        ? _c("h1", { domProps: { innerHTML: _vm._s(_vm.title) } })
        : _vm._e(),
      _c("v-runtime-template", { attrs: { template: _vm.template } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }